import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { createDynamicURL } from '../../utils/dynamicParams';
const initialState = {
    status: 'idle',
    currencies: [],
    bankAccounts: [],
    oneBankAccount: null,
    meta: { currentPage: 0,
        nextPage: false,
        prevPage: false,
        totalPages: 0,
        totalRecords: 0 },
};
export const getAllCurrencies = createAsyncThunk('bankAccount/getAllCurrencies', async () => {
    let data;
    try {
        const response = await axios.get(`/currencies`);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getBanksAccounts = createAsyncThunk('bankAccount/getBanksAccounts', async ({ page, rowsPerPage, orderBy = 'createdAt', order = 'desc', filterName = '', isPaging = true, }) => {
    let data;
    const queryParams = {
        search: {
            name: filterName,
        },
        page: Number(page) + 1,
        perPage: rowsPerPage,
        orderBy,
        sortedBy: order,
        isPaging,
    };
    const url = createDynamicURL('/bankAccounts', queryParams);
    try {
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const createBankAccount = createAsyncThunk('bankAccount/createBankAccount', async (Data) => {
    let data;
    try {
        const response = await axios.post(`/bankAccounts`, { ...Data, iban: Data?.iban?.toString() });
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message);
    }
});
export const getBankAccountById = createAsyncThunk('bankAccount/getBankAccountById', async (id) => {
    let data;
    try {
        const response = await axios.get(`/bankAccounts/${id}`);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const EditBankAccount = createAsyncThunk('bankAccount/EditBankAccount', async ({ id, values }) => {
    let data;
    try {
        const response = await axios.put(`/bankAccounts/${id}`, {
            ...values,
            iban: values?.iban?.toString(),
        });
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const deleteBankAccount = createAsyncThunk('bankAccount/deleteBankAccount', async ({ id, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/bankAccounts/${id}`);
        data = await response.data;
        if (response.status === 200) {
            if (getParams)
                thunkAPI.dispatch(getBanksAccounts({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const deleteManyBankAccount = createAsyncThunk('bankAccount/deleteManyBankAccount', async ({ ids, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/bankAccounts/many`, { data: { ids } });
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getBanksAccounts({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
const slice = createSlice({
    name: 'bankAccount',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllCurrencies.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getAllCurrencies.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.currencies = action.payload.data;
        })
            .addCase(getAllCurrencies.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getBanksAccounts.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getBanksAccounts.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.bankAccounts = action.payload.data;
            state.meta = action.payload.meta;
        })
            .addCase(getBanksAccounts.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getBankAccountById.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getBankAccountById.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.oneBankAccount = action.payload.data;
        })
            .addCase(getBankAccountById.rejected, (state, action) => {
            state.status = 'failed';
        });
    },
});
export const reducer = slice.reducer;
export default slice.reducer;
